<template>
  <div id="mortgage-page">
    <div class="inner-mort">
      <div
        class="intro cvr-bg"
        style="background-image: url('/img/dummy/mort/1.jpg')"
      >
        <div class="wrapper">
          <h2>
            Mortgage Services <br />
            <small>What Services Do We Provide?</small>
          </h2>
          <div class="format-text">
            <p>
              Now that you have found your dream house, you are looking for
              financing options. But all the jargon and calculations are making
              you dizzy. What should you do? Worry not, Rubii is here for you!
            </p>
            <p>
              We are here to help you search, advise on and apply for a
              mortgage, whether it is KPR (house/property mortgage) or KPA
              (apartment mortgage). Full disclosure - we are not a financial
              institution. We do not provide the mortgage but we are here to
              help you secure one.
            </p>
            <p>
              The best part is: if you decide to sign a mortgage through us, our
              mortgage service is free! Contact your Customer Success Agent now
              and let us guide you!
            </p>
          </div>
          <!-- end of format text -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of intro -->

      <div class="filter">
        <div class="wrapper">
          <a href="#" class="button">Quick Ask</a>
          <div class="layer">
            <form>
              <fieldset>
                <label>Type of Property</label>
                <div class="field-select">
                  <select v-model="quickAsk.propertyType">
                    <option
                      v-for="(type, index) in propertyType"
                      :key="index"
                      :value="type.name"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                </div>
                <!-- end of select -->
              </fieldset>
              <fieldset>
                <label>Property Price</label>
                <div class="field-price">
                  <b>Rp</b>
                  <input
                    type="text"
                    v-model="quickAsk.propertyPrice"
                    @keyup="formatRupiah('quickAsk')"
                    required
                  />
                </div>
                <!-- end of field price -->
              </fieldset>
              <fieldset>
                <label>Downpayment</label>
                <div class="field-depe">
                  <span>
                    <select v-model="quickAsk.dpType">
                      <option :value="'percent'">%</option>
                      <option :value="'amount'">Rp</option>
                    </select>
                  </span>
                  <template v-if="quickAsk.dpType == 'percent'">
                    <input
                      type="number"
                      min="0"
                      step="0.5"
                      v-model="quickAsk.dpPercent"
                      required
                    />
                  </template>
                  <template v-else>
                    <input
                      type="text"
                      v-model="quickAsk.dpAmount"
                      @keyup="formatDownPayment('quickAsk')"
                      required
                    />
                  </template>
                </div>
              </fieldset>
              <fieldset>
                <label>Interest Rate</label>
                <input
                  type="number"
                  min="0"
                  step="0.5"
                  v-model="quickAsk.interestRate"
                  required
                />
              </fieldset>
              <fieldset>
                <label><br /></label>
                <template>
                  <button type="button" class="button" @click="redirectToWA">
                    Submit
                  </button>
                </template>
              </fieldset>
            </form>
          </div>
          <!-- end of layer -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of filter -->

      <div class="calc">
        <div class="wrapper">
          <div class="hentry">
            <h2>Mortgage Calculator</h2>
            <div class="format-text">
              <p>
                Wondering how much you would need to pay per month? Use our
                mortgage calculator to approximate your monthly loan payment!
              </p>
            </div>
          </div>
          <!-- end of hentry -->
          <div class="pusher">
            <div class="layer">
              <form @submit.prevent="submitCalculator">
                <fieldset>
                  <label>Propery Type</label>
                  <div class="field-select">
                    <select v-model="calculator.propertyType">
                      <option
                        v-for="(type, index) in propertyType"
                        :key="index"
                        :value="type.name"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                  </div>
                </fieldset>
                <fieldset>
                  <label>Property Price</label>
                  <div class="field-price">
                    <b>Rp</b>
                    <input
                      type="text"
                      v-model="calculator.propertyPrice"
                      @keyup="formatRupiah('calculator')"
                      required
                    />
                  </div>
                </fieldset>
                <div class="gap col-3-2">
                  <fieldset>
                    <label>Downpayment</label>
                    <div class="field-price">
                      <b>Rp</b>
                      <input
                        type="text"
                        v-model="calculator.dpAmount"
                        @keyup="formatDownPayment('calculator')"
                        required
                      />
                    </div>
                  </fieldset>
                  <fieldset>
                    <label>Downpayment</label>
                    <div class="field-price toright">
                      <b>%</b>
                      <input
                        type="number"
                        min="0"
                        step="0.5"
                        v-model="calculator.dpPercent"
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                <fieldset>
                  <label>Loan Amount</label>
                  <div class="field-price">
                    <b>Rp</b>
                    <input
                      type="text"
                      v-model="calculator.loadAmount"
                      @keyup="formatLoan"
                      required
                    />
                  </div>
                </fieldset>
                <div class="gap col-3-2">
                  <fieldset>
                    <label>Period Time</label>
                    <div class="field-price toright wider">
                      <b>Years</b>
                      <input
                        type="number"
                        min="0"
                        step="1"
                        v-model="calculator.periodTime"
                        required
                      />
                    </div>
                  </fieldset>
                  <fieldset>
                    <label>Annual Interest rate</label>
                    <div class="field-price toright">
                      <b>%</b>
                      <input
                        type="number"
                        min="0"
                        step="0.5"
                        v-model="calculator.annualInterest"
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                <div class="gap last">
                  <fieldset v-if="calculator.estimation">
                    <label>Monthly Payment</label>
                    <strong>
                      IDR {{ calculator.estimation | convertToRupiah }}
                    </strong>
                  </fieldset>
                  <fieldset v-else>
                    <label>&nbsp;</label>
                  </fieldset>
                  <fieldset>
                    <button type="submit" class="button">
                      Calculate
                    </button>
                  </fieldset>
                </div>
                <!-- end of gap -->
              </form>
            </div>
            <!-- end of layer -->
            <small>
              * Note that the above calculation is only an estimate. Confirmed
              calculations will be provided by banks and mortgage providers
            </small>
          </div>
          <!-- end of pusher -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of calc -->

      <div class="info" style="display: none;">
        <div class="wrapper">
          <h2>Rules & FAQ Partaining to Mortgage</h2>
          <div class="list">
            <template v-for="(rule, index) in rules">
              <div class="accord-item" :key="index">
                <div class="acc-head">
                  <h3>{{ rule.title }}</h3>
                </div>
                <div class="acc-body">
                  <div class="format-text">
                    <ul>
                      <template v-for="(content, zindex) in rule.description">
                        <li :key="zindex">
                          {{ content.value }}
                        </li>
                      </template>
                    </ul>
                  </div>
                  <!-- end of format text -->
                </div>
                <!-- end of acc body -->
              </div>
            </template>
          </div>
          <!-- end of list -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of info -->
    </div>
    <!-- end of inner mort -->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Mortgage",
  metaInfo: {
    title: "Mortgage Service"
  },
  data() {
    return {
      propertyType: [],
      rules: [],
      quickAsk: {
        propertyType: null,
        propertyPrice: null,
        dpType: "amount",
        dpAmount: null,
        dpPercent: null,
        interestRate: null
      },
      calculator: {
        propertyType: null,
        propertyPrice: null,
        dpAmount: null,
        dpPercent: null,
        loadAmount: null,
        periodTime: null,
        annualInterest: null,
        estimation: null
      }
    };
  },
  async mounted() {
    this.getPropertyType();
    await this.getMortgageRules();
    $(".accord-item .acc-head").on("click", function() {
      var self = $(this),
        parent = self.closest(".accord-item"),
        target = parent.find(".acc-body");

      target.stop().slideToggle("fast", function() {
        parent.toggleClass("expanded");
      });
    });
  },
  methods: {
    redirectToWA() {
      window.location.href = "https://wa.me/+6287708779768";
    },
    getPropertyType() {
      this.$axios
        .get(`/misc/property-types`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.propertyType = response.data.data;
          this.quickAsk.propertyType = this.propertyType[0].name;
          this.calculator.propertyType = this.propertyType[0].name;
        });
    },
    formatRupiah(form) {
      if (form == "quickAsk") {
        const angka = this.convertToAngka(this.quickAsk.propertyPrice);
        const rupiah = this.convertToRupiah(angka, false);
        this.quickAsk.propertyPrice = rupiah;
      } else {
        const angka = this.convertToAngka(this.calculator.propertyPrice);
        const rupiah = this.convertToRupiah(angka, false);
        this.calculator.propertyPrice = rupiah;
      }
    },
    formatDownPayment(form) {
      if (form == "quickAsk") {
        const angka = this.convertToAngka(this.quickAsk.dpAmount);
        const rupiah = this.convertToRupiah(angka, false);
        this.quickAsk.dpAmount = rupiah;
      } else {
        const angka = this.convertToAngka(this.calculator.dpAmount);
        const rupiah = this.convertToRupiah(angka, false);
        this.calculator.dpAmount = rupiah;
      }
    },
    formatLoan() {
      const angka = this.convertToAngka(this.calculator.loadAmount);
      const rupiah = this.convertToRupiah(angka, false);
      this.calculator.loadAmount = rupiah;
    },
    submitQuickAsk() {
      let dpAmount = 0;
      if (this.quickAsk.dpType == "percent") {
        const price = this.convertToAngka(this.quickAsk.propertyPrice);
        dpAmount = price * (this.quickAsk.dpPercent / 100);
      } else {
        dpAmount = this.convertToAngka(this.quickAsk.dpAmount);
      }

      this.$axios
        .post(`/mortgage/quick-ask`, {
          propertyType: this.quickAsk.propertyType,
          propertyPrice: this.convertToAngka(this.quickAsk.propertyPrice),
          downpayment: dpAmount,
          interestRate: this.quickAsk.interestRate
        })
        .then(response => {
          console.log(response.data);
          this.$swal({
            icon: "success",
            text: response.data.message
          });
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    },
    submitCalculator() {
      this.$axios
        .post(`/mortgage/calculate`, {
          propertyPrice: this.convertToAngka(this.calculator.propertyPrice),
          downpayment: this.convertToAngka(this.calculator.dpAmount),
          percentage: this.calculator.dpPercent,
          interestRate: this.calculator.annualInterest,
          duration: this.calculator.periodTime
        })
        .then(response => {
          this.calculator.estimation = response.data.data.estimateAmount;
          this.$swal({
            icon: "success",
            text: response.data.message
          });
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    },
    async getMortgageRules() {
      await this.$axios.get(`/mortgage/rules`).then(async response => {
        const rules = response.data.data.rows;
        for (let index = 0; index < rules.length; index++) {
          const rule = rules[index];
          await this.$axios.get(`/mortgage/rules/${rule.id}`).then(detail => {
            this.rules.push({
              title: detail.data.data.title,
              description: detail.data.data.descriptions
            });
          });
        }
      });
    }
  }
};
</script>
